class AppURL{


    static BaseURL="https://myapi.pusantrading.com/"

    static Categories=this.BaseURL+"api/categories";
    //static Categories=this.BaseURL+"api/categories";

    static SubCategories(id){
        return this.BaseURL+"api/subcategories/"+id;
    }

    static AllSubCategories=this.BaseURL+"api/subcategories";
    static Products(id){
        return this.BaseURL+"api/products/"+id;
    }

    static ProductDetails(id){
        return this.BaseURL+"api/productdetails/"+id;
    }

    
    static SendEmail=this.BaseURL+"api/contact";
  
}

export default AppURL