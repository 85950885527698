import React, { Component,Fragment } from 'react'

import { Container,Row,Col, Form,Button } from 'react-bootstrap'
import Footer from '../components/common/Footer'
import NavMenu from '../components/common/NavMenu'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import AppURL from '../../api/AppURL';
import axios from 'axios';

function ContactPage(){
  
const SendEmail = (e) => {
    e.preventDefault();
    if(e.target.name.value===''){
        NotificationManager.warning('Please enter your name');
    }

    else if(e.target.useremail.value===''){
        NotificationManager.warning('Please enter your valid email');
    }

   else if(e.target.message.value===''){
        NotificationManager.warning('Please enter your message');
    }

else{

    const data={
        name:e.target.name.value,
        email:e.target.useremail.value,
        body:e.target.message.value,
        }


        axios.post(AppURL.SendEmail,data).then(response =>{  
             if(response.status===200){
                NotificationManager.success("We have Recieved your messgae , we will get back to you ASAP.");
                e.target.reset();   
            }
     
            else{
                NotificationManager.warning('Something Went Wrong, Please try again Later');
     }
        })
}




}
    return(
        <Fragment>
            <NavMenu></NavMenu>
  <Container>

    <Row className="p-2">

    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Contact Us</h2>
        </div>

        <div class="row gx-lg-0 gy-4">

          <div class="col-lg-4">

            <div class="info-container d-flex flex-column align-items-center justify-content-center">
              <div class="info-item d-flex">
                <div>
                  <h4>Location:</h4>
                  <p>P.O.Box:40762, Baniyas Square Behind Mount Royal Hotel, 
                    Deira Dubai-United Arab Emirates</p>
                </div>
              </div>

              <div class="info-item d-flex">
              <i className='fa fa-envelope'></i>
                <div>
                  <h4>Email:</h4>
                  <p>sales@pusantrading.com</p>
                </div>
              </div>

              <div class="info-item d-flex">
              <i className='fa fa-phone'></i>
                <div>
                  <h4>Call:</h4>
                  <p>+971 4 2216013</p>
                </div>
              </div>

              <div class="info-item d-flex">
              <i className='fa fa-clock'></i>
                <div>
                  <h4>Open Hours:</h4>
                  <p>Mon-Sat: 8AM - 8PM</p>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-4">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14431.991847723175!2d55.305991!3d25.270654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x68c54b0b6ae8afe5!2sPushan%20Trading%20Co%20LLC!5e0!3m2!1sen!2sae!4v1667752937107!5m2!1sen!2sae" width="600" height="500" styles="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    
          </div>
          <div class="col-lg-4">
            <form  class="php-email-form" onSubmit={SendEmail}>
              <div class="row">
              <h5>Send Us Message</h5>
              </div>
              <div class="form-group mt-3">

                <Form.Control  type="text" name="name" className="form-control m-2" placeholder="Full Name" />
                </div>
              <div class="form-group mt-3">

                <Form.Control  type="email" name="useremail" className="form-control m-2" placeholder="Enter your Email" />
              </div>
              <div class="form-group mt-3 textboxleftmargin">
                <textarea class="form-control" name="message" rows="7" placeholder="Message" ></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div  class="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>

    </section>

               </Row>
               <NotificationContainer/>
          </Container> 
          <Footer></Footer>

</Fragment>    
    )
}
export default ContactPage