import React, { Component, Fragment ,useEffect,useState,useReducer,useRef} from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import AppURL from '../../../api/AppURL'
import {Link, useNavigate} from 'react-router-dom';

function NavMenu(){
const navRef=useRef();
 const navigate =useNavigate();
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const[mcat,setMCat]=useState([]);
  const[item,setItems]=useState([]);
  const[cat,setCat]=useState([]);
  const[product,setProducts]=useState([]);
  const [toggleMenu,setToggleMenu]=useState(false)
const [screenWidth,setScreenWidth]=useState(window.innerWidth)

useEffect(()=>{
  const changeWidth=()=>{
    setScreenWidth(window.innerWidth)
  }

  window.addEventListener('resize', changeWidth)
  return()=>{
    window.removeEventListener('resize',changeWidth);
  }
})

  const toggleNav=()=>{
  setToggleMenu(!toggleMenu);
}

  const showNavBar=()=>{
    navRef.current.classList.toggle("responsive_nav");
}
 

  const Category=(e,id)=>{
    e.preventDefault();
//alert(id);
    navigate('/category',{state:{cid:id}});
    //window.location.reload();
   // forceUpdate();        
     }

  return(

<Fragment>
<header id="header" class="header d-flex align-items-center">

<div class="container-fluid container-xl d-flex align-items-center justify-content-between">
  <Link to={'/'} class="logo d-flex align-items-center">

  <h1>Pusan<span>.</span></h1>
  </Link>
  <nav>
    {(toggleMenu || screenWidth > 500) &&(
      <ul className='list'>
      <li className='items'><Link className='purewhitenav' to="/" > Home</Link></li>
      <li className='items'><Link className='purewhitenav' to="/all-products" > Products</Link></li>
      <li className='items'><Link className='purewhitenav' to="/about-us" > About Us</Link></li>
      <li className='items'><Link className='purewhitenav' to="/contact" > Contact Us</Link></li>
      </ul>

    )}

    
 
    <button className="btnnav purewhite" onClick={toggleNav}><i className='fa fa-bars'></i></button>
  </nav>
  </div>
  </header>

    
        </Fragment>

        
    
    )
}

export default NavMenu