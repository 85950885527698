import React, { Component, Fragment ,useEffect,useState,useReducer} from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import AppURL from '../../api/AppURL';
import {Link, useNavigate} from 'react-router-dom';
import NavMenu from '../components/common/NavMenu'
import Footer from '../components/common/Footer';
import  ReactDOM  from 'react-dom';
import parse from 'html-react-parser';


//export class HomePage extends Component {
  function ProductDetail(){
    const location = useLocation();
    const navigate =useNavigate();
    const[item,setItems]=useState([]);
    //const[cate,setCat]=useState([]);
    const[image,setImages]=useState([]);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const[cat,setCat]=useState([]);
    const[product,setProducts]=useState([]);
    
    const ProductID=location.state.pid;

    const [loading,setLoading]=useState(true);
    const Category=(e,id)=>{
        e.preventDefault();
        navigate('/category',{state:{cid:id}});
         }

         const ProductLink=(e,id)=>{
            e.preventDefault();

            axios.get(AppURL.ProductDetails(id))
            .then(response => {
              setItems(response.data.productDetails);
              setImages(response.data.productImages);
       
                if(response){

                    axios.get(AppURL.Categories)
                    .then(response => {
                      setCat(response.data);
                    });

                    
                    axios.get(AppURL.Products(4))
                    .then(response => {
                      setProducts(response.data);
                      setLoading(false);
                    });
        
                }
            });

            navigate('/product-detail',{state:{pid:id}});
        
        useEffect();   
        }


      // const imageDisplay=(e)=>{
    const imageDisplay=(e,id)=>{
        e.preventDefault();
        let imgsrc=e.target.getAttribute('src');
        let bigimage = document.getElementById('previewImg');
            ReactDOM.findDOMNode(bigimage).setAttribute('src',imgsrc);
       }
      useEffect(()=>{
        window.scrollTo(0, 0)
              axios.get(AppURL.ProductDetails(ProductID))
                .then(response => {
                  setItems(response.data.productDetails);
                  setImages(response.data.productImages);
           
                    if(response){

                        axios.get(AppURL.Categories)
                        .then(response => {
                          setCat(response.data);
                        });

                        
                        axios.get(AppURL.Products(4))
                        .then(response => {
                          setProducts(response.data);
                          setLoading(false);
                        });
            
                    }
                });

             }, [ignored]);
       // console.log(item['0']['id']);
//render() {
    if(loading){
        return <h4>Loading .....</h4>
   } 

   else{ 
    return (
      <Fragment>
        <NavMenu></NavMenu>
        <div class="breadcrumbs">
      <nav>
        <div class="container">
          <ol>
            <li><h4><strong>{item['0']['title']}</strong></h4></li>
          </ol>
        </div>
      </nav>
    </div>







    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">

        <div class="row g-5">

          <div class="col-lg-8">

            <article class="blog-details">

              <div class="post-img">
                <img src={AppURL.BaseURL+item['0']['image']} alt="" class="img-fluid" id='previewImg'></img>
            <div className='row mt-1 p-1'>
           {
            image.map((img,i)=>{
            return(
                <div class="col-xl-3 col-md-4 portfolio-item filter-app">
                <div class="portfolio-wrap">
                <img onClick={(e)=> imageDisplay(e,i)} src={AppURL.BaseURL+img.image} class="img-fluid " alt=""></img>       
                  </div>
                  </div>
            )
            })
        }
              
                </div>
              </div>
        




              <div class="content">
                
                <p>{parse(item['0']['description'])}</p>
            
              </div>


            </article>




          </div>

          <div class="col-lg-4">

            <div class="sidebar">

            <div class="sidebar-item recent-posts">
                <h3 class="sidebar-title">Related Products</h3>

                <div class="mt-3">
                {product.map((pro,i)=>{
                    return(
                        <div class="post-item mt-3">
                        <img src={AppURL.BaseURL+pro.image} alt=""></img>
                        <div>
                        <Link onClick={(e)=> ProductLink(e,pro.id)}><h4>{pro.title}</h4></Link>
                          <time >&nbsp;</time>
                        </div>
                      </div>
                    )
                })
                }
               

                </div>

              </div>



              <div class="sidebar-item categories">
                <h3 class="sidebar-title">Product Categories</h3>
                <ul class="mt-3">
               { cat.map((CatList,i)=>{
                return(
                    <Link >  <li onClick={(e)=> Category(e,CatList.id)}>{CatList.category_name} </li></Link>
                )
               }
                )}
          
        
                </ul>
              </div>

        


            </div>

          </div>
        </div>

      </div>
    </section>

<Footer></Footer>
      </Fragment>
    )
  }
  
}

export default ProductDetail