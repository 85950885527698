import React, { Component, Fragment } from 'react'

export class Footer extends Component {
  render() {
    return (
      <Fragment>
        <footer id="footer" class="footer">

        <div class="container mt-4 textpaddingfooter">
      <div class="copyright">
        &copy; <strong>Copyright <strong><span>Pusan Trading</span></strong>. All Rights Reserved</strong>
      </div>
      <div class="credits">
      Designed by <span >Pusan IT Department</span>
      </div>
    </div>
        </footer>
      </Fragment>
    )
  }
}

export default Footer