import React, {Fragment ,useEffect,useState,useReducer} from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import AppURL from '../../api/AppURL';
import {Link, useNavigate} from 'react-router-dom';
import NavMenu from '../components/common/NavMenu'
import Footer from '../components/common/Footer';



//export class HomePage extends Component {
  function CategoryPage(){
    const location = useLocation();
    const navigate =useNavigate();
    const[item,setItems]=useState([]);
    const[cat,setCat]=useState([]);
    const CategoryID=location.state.cid;
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const Category=(e,id)=>{
      e.preventDefault();
        navigate('/products',{state:{sid:id}});
       }

      useEffect(()=>{
        window.scrollTo(0, 0);
              axios.get(AppURL.SubCategories(CategoryID))
                .then(response => {

                  setItems(response.data);
               
                  axios.get(AppURL.Categories)
                  .then(response => {
  
                    setCat(response.data);
                    
                  });
                });

            
                console.log(item);
                //forceUpdate();
             }, []);


             

//render() {
    return (
      <Fragment>
        <NavMenu></NavMenu>


  <section id="portfolio" class="portfolio sections-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-header">

    {
        cat.map((cat,i)=>{
            if(cat.id==CategoryID){
                return(
                    <h2>{cat.category_name}</h2>
                )
            }
        })
    }
          
        </div>

        <div class="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order" data-aos="fade-up" data-aos-delay="100">

          <div class="row gy-4 portfolio-container">




            {
        //item.map((single,i)=>{
          item.map((CatList,i)=>{
           let  $image=AppURL.BaseURL+CatList.image;
            //console.log($image);
            return(
            <div class="col-xl-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
              <Link onClick={(e)=> Category(e,CatList.id)} className="h4 btn" data-gallery="portfolio-gallery-app" class="glightbox"><img src={$image} class="img-fluid" alt=""></img></Link>
                <div class="portfolio-info">
                  <Link onClick={(e)=> Category(e,CatList.id)}  className='categorybottomheading'><h4 title="More Details" className="h4 btn categoryheading">{CatList.name}</h4></Link>
                </div>
              </div>
            </div>
          )
        })
      }

          </div>

        </div>

      </div>
    </section>
<Footer></Footer>
      </Fragment>
    )
  
}

export default CategoryPage