import React, { Component,Fragment } from 'react'

import { Container,Row,Col, Form,Button } from 'react-bootstrap'
import NavMenu from '../components/common/NavMenu'
import about from '../image/about.jpg';
import about2 from '../image/about-2.jpg';
import Footer from '../components/common/Footer';
function AboutPage(){
    return(
        <Fragment>
            <NavMenu></NavMenu>
  <Container>

    <Row className="p-2">
    <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>About Us</h2>
          <p>PUSAN TRADING CO LLC, established in the U.A.E. in 1987, is a General Trading & Distribution company with diverse business lines</p>
        </div>

        <div class="row gy-4">
          <div class="col-lg-6">
            <h3>We follow ISO 9001:2015, and all International Quality Standard</h3>
            <img src={about} class="img-fluid rounded-4 mb-4" alt=""></img>
            <p>As a leading supplier to the industry, We strive to provide the best service possible and are proud to have it. We believe that the true value of our work is customer satisfaction.</p>
            <p>Pusan Trading CO based in Dubai is a leading trading company dealing in Hardware Items, Light Engineering Equipment, Tools, Welding Equipment, High Pressure Fittings, Spare parts, Pipe & Pipe Fittings, Industrial Glues, Fasteners, Adhesives, Paints, Cleaning Supplies, Safety gear, Valves, Steel Wire, Ropes, Rope Slings, Packaging items, Fabrication equipment etc.</p>
          </div>
          <div class="col-lg-6">
            <div class="content ps-0 ps-lg-5">
              <p class="fst-italic">
              With 35 years of expertise & experience, we are committed to bring in a new era of change innovative ideas leading to a future filled with success and endless possibilities. Pusan Trading CO has always upheld these attributes, which helped achieve numerous milestones and successes, allowing us to evolve as Middle East’s leading supplier.
              </p>
              <ul>
                <li><i class="bi bi-check-circle-fill"></i>  Holding backup inventory in our certified Warehousing facility.</li>
                <li><i class="bi bi-check-circle-fill"></i> In house Logistics team serving MENA + South Asia effectively</li>
                <li><i class="bi bi-check-circle-fill"></i> 50+ diverse team members customer service team in UAE catering to MENA & South East Asia Market,Detailed assessment of customer projects & offering complete solutions</li>
              </ul>

              <div class="position-relative mt-4">
              <img src={about2} class="img-fluid rounded-4" alt=""></img>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
               </Row>
          </Container> 
          <Footer></Footer>

</Fragment>    
    )
}
export default AboutPage