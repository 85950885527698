import React, { Component, Fragment ,useEffect,useState,useReducer} from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import AppURL from '../../api/AppURL';
import {Link, useNavigate} from 'react-router-dom';
import NavMenu from '../components/common/NavMenu'
import Footer from '../components/common/Footer';



//export class HomePage extends Component {
  function ProductsPage(){
    const location = useLocation();
    const navigate =useNavigate();
    const[item,setItems]=useState([]);
    const[cat,setCat]=useState([]);
    const CategoryID=location.state.sid;
    const Product=(e,id)=>{
      e.preventDefault();
      navigate('/product-detail',{state:{pid:id}});
       }

      useEffect(()=>{
        window.scrollTo(0, 0);
              axios.get(AppURL.Products(CategoryID))
                .then(response => {
                  setItems(response.data);
                });

                axios.get(AppURL.AllSubCategories)
                .then(response => {
                  setCat(response.data);
                });


             }, []);
             console.log(cat);

//render() {
    return (
      <Fragment>
        <NavMenu></NavMenu>


  <section id="portfolio" class="portfolio sections-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-header">

    {
        cat.map((cat,i)=>{
            if(cat.id==CategoryID){
                return(
                    <h2>{cat.name}</h2>
                )
            }
        })
    }
          
        </div>

        <div class="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order" data-aos="fade-up" data-aos-delay="100">

          <div class="row gy-4 portfolio-container">


            {
        //item.map((single,i)=>{
          item.map((CatList,i)=>{
           let  $image=AppURL.BaseURL+CatList.image;
            //console.log($image);
            return(
                <div class="col-xl-4 col-md-6">
                <article>

                <div class="post-img">
                <img src={$image} alt="" class="img-fluid"></img>
                </div>


                <h3 class="title">
                <Link onClick={(e)=> Product(e,CatList.id)}>{CatList.title}</Link>
                </h3>

                </article>
                </div>
          )
        })
      }

          </div>

        </div>

      </div>
    </section>
<Footer></Footer>
      </Fragment>
    )
  
}

export default ProductsPage