import React, { Component, Fragment ,useEffect,useState,useReducer} from 'react'
import axios from 'axios';
import AppURL from '../../api/AppURL';
import {Link, useNavigate} from 'react-router-dom';
import NavMenu from '../components/common/NavMenu'
import Footer from '../components/common/Footer';


//export class HomePage extends Component {
  function HomePage(){
    const navigate =useNavigate();
    const[item,setItems]=useState([]);

    const Category=(e,id)=>{
      e.preventDefault();
      navigate('/category',{state:{cid:id}});
      //alert(id);
      //thisClicked.closest("tr").remove();
      //console.log(email);
       }

      useEffect(()=>{
        window.scrollTo(0, 0);
              axios.get(AppURL.Categories)
                .then(response => {
                  setItems(response.data);
                });

             }, []);

//render() {
    return (
      <Fragment>
        <NavMenu></NavMenu>
 
        <section id='hero' className="backgroundimagediv hero">
    <div class="container position-relative">
      <div class="row gy-5 marginforherotext" data-aos="fade-in">
        <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
          <h3 className='purewhite'>Welcome to Pusan Trading LLC</h3>
          <p className='pwhite'>Established in 1987, pusan trading providing industry-best products, intelligent solutions and exceptional service – helping your company improve productivity, efficiency and profitability</p>
          <div class="d-flex justify-content-center justify-content-lg-start">
            <Link to={'contact'} class="btn-get-started">Contact Us</Link>
            
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2">
          <img  class="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="100"></img>
        </div>
      </div>
    </div>



    <div class="icon-boxes position-relative">
      <div class="container position-relative">
        <div class="row gy-4 mt-5">

          <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div class="icon-box">
              <div class="icon"><i class="fa fa-gem"></i></div>
              <h4 class="title"><a href="" class="stretched-link">High Quality</a></h4>
            </div>
          </div>

          <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div class="icon-box">
              <div class="icon"><i class="fa fa-ship"></i></div>
              <h4 class="title"><a href="" class="stretched-link">On Time Delivery</a></h4>
            </div>
          </div>

          <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div class="icon-box">
              <div class="icon"><i class="fa fa-headset"></i></div>
              <h4 class="title"><a href="" class="stretched-link">Customer Service</a></h4>
            </div>
          </div>

          <div class="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="500">
            <div class="icon-box">
              <div class="icon">1000+</div>
              <h4 class="title"><a href="" class="stretched-link">Happy Customers</a></h4>
            </div>
          </div>

        </div>
      </div>
    </div>

  </section>


 




  <section id="portfolio" class="portfolio sections-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Our Products</h2>
          <p>Our team is proud to keep up with technology on the move to deliver you the latest innovative and effective solutions</p>
        </div>

        <div class="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order" data-aos="fade-up" data-aos-delay="100">

          <div class="row gy-4 portfolio-container">
        
            {
        //item.map((single,i)=>{
          item.map((CatList,i)=>{
           let  $image=AppURL.BaseURL+CatList.image;
            //console.log($image);
            return(
            <div class="col-xl-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
              <Link onClick={(e)=> Category(e,CatList.id)} className="h4 btn" data-gallery="portfolio-gallery-app" class="glightbox"><img src={$image} class="img-fluid" alt=""></img></Link>
                <div class="portfolio-info">
                  <h4 onClick={(e)=> Category(e,CatList.id)}  className='categorybottomheading'><Link title="More Details" className="h4 btn categoryheading">{CatList.category_name}</Link></h4>
                </div>
              </div>
            </div>
          )
        })
      }

          </div>

        </div>

      </div>
    </section>
<Footer></Footer>
      </Fragment>
    )
  
}

export default HomePage