import React, { Component, Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import AppRoute from "./asset/route/AppRoute";

export class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <AppRoute></AppRoute>
      </BrowserRouter>
    );
  }
}

export default App;
