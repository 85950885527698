import React, { Component, Fragment } from "react";
import { Route, Routes, Router, Switch } from "react-router";
import AboutPage from "../Pages/AboutPage";
import CategoryPage from "../Pages/CategoryPage";
import ContactPage from "../Pages/ContactPage";
import HomePage from "../Pages/HomePage";
import ProductDetail from "../Pages/ProductDetail";
import ProductPage from "../Pages/ProductPage";
import ProductsPage from "../Pages/ProductsPage";

export class AppRoute extends Component {
  render() {
    //console.log(this.state.user.username);

    return (
      <Fragment>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/category/" element={<CategoryPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/product-detail" element={<ProductDetail />} />
          <Route path="/all-products" element={<ProductPage />} />
        </Routes>
      </Fragment>
    );
  }
}

export default AppRoute;
