import React, { Component,Fragment ,useEffect,useState} from 'react'
import { Container,Row,Col, Form,Button } from 'react-bootstrap'
import Footer from '../components/common/Footer'
import NavMenu from '../components/common/NavMenu'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import AppURL from '../../api/AppURL';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';

function ProductPage(){
  
    const navigate =useNavigate();
    const[item,setItems]=useState([]);

    const Category=(e,id)=>{
      e.preventDefault();
      navigate('/category',{state:{cid:id}});
       }

      useEffect(()=>{
        window.scrollTo(0, 0);
              axios.get(AppURL.Categories)
                .then(response => {
                  setItems(response.data);
                });

             }, []);
    return(
        <Fragment>
            <NavMenu></NavMenu>
        <Container>
        <Row className="p-2">
        <section id="portfolio" class="portfolio sections-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Our Products</h2>
        </div>

        <div class="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order" data-aos="fade-up" data-aos-delay="100">

          <div class="row gy-4 portfolio-container">
        
            {
        //item.map((single,i)=>{
          item.map((CatList,i)=>{
           let  $image=AppURL.BaseURL+CatList.image;
            //console.log($image);
            return(
            <div class="col-xl-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
              <Link onClick={(e)=> Category(e,CatList.id)} className="h4 btn" data-gallery="portfolio-gallery-app" class="glightbox"><img src={$image} class="img-fluid" alt=""></img></Link>
                <div class="portfolio-info">
                  <h4 onClick={(e)=> Category(e,CatList.id)}  className='categorybottomheading'><Link title="More Details" className="h4 btn categoryheading">{CatList.category_name}</Link></h4>
                </div>
              </div>
            </div>
          )
        })
      }

          </div>

        </div>

      </div>
    </section>
               </Row>
   
          </Container> 
          <Footer></Footer>

</Fragment>    
    )
}
export default ProductPage